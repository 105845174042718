<template v-if="post_contact"> 
 <div class="" style="margin-top: 120px;" > 

  <section class="hero-header">
    <div class="section-inner" >
      <h1 v-if="this.post_contact" v-html="post_contact.title" >  </h1>
      <div class="content" >
      
       <p v-if="this.post_contact" v-html="post_contact.section_presentation.content"></p>
      </div>
       <div 
        v-if="!statuSend && this.post_contact" 
        class="wpcf7-form" 
        v-html="post_contact.form" 
        v-on:submit.prevent="formSumitted" />
      <div class="validation-message" v-if="statuSend">  {{response_post}}  </div>
    </div>

  </section>

   </div>
</template>

<script>
import headful from 'headful';
export default {
  name: 'Contact',
    created() {
    this.get_fetchData()
  
  },
    data() {
      return {
        post_contact: null,
        response_post : null,
        statuSend : false,
      }
    },
  methods: {
    get_fetchData() {
      /* fetch('https://25et7.fr/dev/2022/25et7/get_page/contact-developpeur-wordpress-freelance') */
      fetch('https://25et7.fr/25et7/get_page/contact-developpeur-wordpress-freelance') 
          .then(async response => { const post_propos_data = await response.json();
         if (post_propos_data) { 
           this.post_contact = post_propos_data; 
                headful({
                    title: post_propos_data.seo.title,
                    description: post_propos_data.seo.description,
                });}

         })
    },
    formSumitted(e){
       e.preventDefault();
      let formData = new FormData();
      e.target.querySelectorAll('.wpcf7-form-control').forEach(element => {

        if(!element.classList.contains('wpcf7-submit'))  {
          formData.append( [element.name], element.value )
          }  
          });

        if(formData){this.postForm(formData)}
      },

    postForm(data){
        const requestOptions = {
              method: "POST",
              body: data
            };
         /* fetch('https://25et7.fr/dev/2022/25et7/contact-form-7/v1/contact-forms/71/feedback', requestOptions ) */
          fetch('https://25et7.fr/25et7/contact-form-7/v1/contact-forms/71/feedback', requestOptions )
           .then( async response => { const response_post = await response.json() 

            if (response_post) { 
              this.response_post = response_post.message;
              if(response_post.status == "mail_sent"){
                 this.statuSend = true
              }else{
                  var errorMessage = document.createElement("span");
                  errorMessage.classList.add("errorValue");
                  errorMessage.innerHTML = response_post.message;

                  document.getElementsByClassName('wpcf7-response-output')[0].appendChild(errorMessage)
                  this.findError(response_post.invalid_fields)
              }
            }

          })
        .catch(error => {
            console.log('Error --> ' + error)
        })
      },
      findError(fields){
        for (var i = 0; i < fields.length; i++) {
          var errorMessage = document.createElement("span");
          errorMessage.classList.add("errorValue");
          errorMessage.innerHTML = fields[i].message;
          document.querySelectorAll(fields[i].into)[0].appendChild(errorMessage)
        }
      }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
.hero-header .section-inner{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;}
.hero-header .section-inner > div{
  width: 49%;
  padding: 20px;}
.hero-header .section-inner h1{
  font-family: 'hammersmithoneregular', sans-serif;
  font-size: 60px;
  line-height: 0.9;
  color: #3d8294;
  width: 100%;}
.hero-header .section-inner .content p{
  white-space: pre-line;
  line-height: 2.5;
  color: #465054;}
.wpcf7-form >>> label{
  font-weight: bolder;
  color: #465054;
  margin-bottom: 40px;
  font-size: 20px;}
.wpcf7-form >>> .wpcf7-text{
  color: #465054;
  background: transparent;
  border: 0;
  border-bottom: 2px solid #3d8294;
  font-size: 18px;
  padding: 7px 15px;
  margin-top: 10px;
  padding-top: 0;
  padding-left: 0;}
.wpcf7-form >>> .wpcf7-text:focus-visible,
.wpcf7-form >>> .wpcf7-text:hover,
.wpcf7-form >>> .wpcf7-textarea:focus-visible,
.wpcf7-form >>> .wpcf7-textarea:hover{  outline: none;}
.wpcf7-form >>> .errorValue{
font-size: 13px;
color: #eb8a00;
font-weight: bolder;}

.wpcf7-form >>> .wpcf7-textarea{
background: #ffffff2e;
border-color: #3d8294;
font-size: 18px;
padding: 7px;
margin-top: 10px;}
.wpcf7-form >>> input[type="submit"]{
background: #eb8a00;
color: #465054;
padding: 15px 30px;
font-size: 20px;}

.validation-message{
  font-weight: bold;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #3d8294;
  color: #3d8294;}

  @media screen and (max-width: 1280px) {

}

@media screen and (max-width: 1100px) {

}
@media screen and (max-width: 1024px) {

}
@media screen and (max-width: 960px) {
  .hero-header .section-inner h1{text-align: center;}
.hero-header .section-inner > div{width: 100%;;}
}
@media screen and (max-width: 768px) {

}
@media screen and (max-width: 600px) {
	
}
@media screen and (max-width: 480px) {
	
}

</style>